import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <div className="h-screen flex flex-col items-center justify-center gap-12 bg-azulkatun">
    <StaticImage
      src="../images/logoSmall.png"
      width={100}
      quality={90}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Katun Cocodrilo Cósmico"
      style={{}}
    />
    <StaticImage
      src="../images/cocodriloBlanco200x200.png"
      width={200}
      quality={90}
      formats={["AUTO", "WEBP", "AVIF"]}
      alt="Katun Cocodrilo Cósmico"
      style={{}}
    />
  </div>
)

export default IndexPage
